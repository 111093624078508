import React, { useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import navigationLinks from "../constants/navigation-links"


const SubNavigation = ({ styleClass }) => {
  const [pathName, setPathName] = React.useState(false)
  const tempLinks =
    navigationLinks.find(x=>x.url === '/').children.map(link => {
      const itemClassNames = classNames({
        last: navigationLinks.length === link.id,
        currentPage: pathName === link.url
      })
      return (
        <li key={link.id} className={itemClassNames}>
              <Link to={link.url}>
                <span style={{ verticalAlign: "top" }}>{link.text}</span>
              </Link>
        </li>
      )
    })


  useEffect(() => {
    const path = window.location.pathname.split('/')
    if(path.length > 3) {
      setPathName('/' + path[1] + '/' + path[2])
    } else {
      setPathName(path.join('/'))
    }
  }, [pathName])

  return (
    <ul className={classNames("page-links", styleClass)}>{tempLinks}</ul>
  )
}

export default SubNavigation
