const links = [
    {
        id: 1,
        text: "EDITING FLOOR BLUES",
        url: "https://catstevens.com/editing-floor-blues/"
    },
    {
        id: 2,
        text: "TERMS AND CONDITIONS",
        url: "https://privacypolicy.umusic.com/terms/"
    },
    {
        id: 3,
        text: "PRIVACY POLICY",
        url: "/privacy-policy"
    },
    {
        id: 4,
        text: "PARTNERS",
        url: "https://catstevens.com/partners/"
    },
    {
        id: 5,
        text: "REDROOM FAQ & TERMS",
        url: "https://catstevens.com/redroom-terms/"
    },
    {
        id: 6,
        text: "REDROOM LOGIN",
        url: "https://catstevens.com/redroom/membership/login/"
    }
]

export default links