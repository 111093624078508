import React, { useEffect } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const MainLayout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsOpen(value => !value)
  }

  useEffect(() => {
    if (isOpen === false) {
      document.body.classList.remove("sidebar-open")
    } else {
      document.body.classList.add("sidebar-open")
    }
  }, [isOpen])

  return (
    <div className="container-fluid">
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {children}
      <CookieConsent
        location="bottom"
        declineButtonText="Decline"
        enableDeclineButton={false}
        buttonText="Accept"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookie-consent"
        buttonClasses="btn btn-primary"
        declineButtonClasses="btn btn-primary"
      >
        We use cookies on our website to personalize your experience and improve our efforts. 
        By continuing, you agree to the terms of our <Link to="/privacy-policy">Privacy Policy.</Link>
      </CookieConsent>
      <Footer />
    </div>
  )
}

export default MainLayout
