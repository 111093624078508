import React from 'react'
import csLogo from '../assets/images/cs-logo.png'
import { Link } from "gatsby"
import links from "../constants/footer-navigation-links"
import Facebook from '../assets/images/footer-social-icons/facebook.svg'
import Twitter from '../assets/images/footer-social-icons/twitter.svg'
import Instagram from '../assets/images/footer-social-icons/instagram.svg'
import Youtube from '../assets/images/footer-social-icons/youtube.svg'
import Spotify from '../assets/images/footer-social-icons/spotify.svg'
import Music from '../assets/images/footer-social-icons/music.svg'

const Footer = () => {
    const tempLinks = links.map(link => {
        return (
            <li key={link.id}>
                <Link to={link.url}>
                    <span style={{ verticalAlign: "top" }}>{link.text}</span>
                </Link>
            </li>
        )
    })
    return (
        <footer>
            <div className="before-footer-image" />
            <div className="footer">
                <div>
                    <img
                        src={csLogo}
                        alt="cs logo"
                        width={163}
                        height={92}
                    />
                </div>
                <div className="footer-links">
                    <ul style={{paddingLeft:0}}>
                        {tempLinks}
                        <li>© YUSUF ISLAM / CAT STEVENS, 2021</li>
                    </ul>
                </div>
                <div className="subs-modal">
                    <a className="subscribe-button" href="https://catstevens.com/email-signup/">
                        SUBSCRIBE TO NEWSLETTER
                    </a>
                    <div className="icons">
                        <a className="btn" href="https://www.facebook.com/YusufCatStevens/"><img src={Facebook} /></a>
                        <a className="btn" href="https://twitter.com/yusufcatstevens"><img src={Twitter} /></a>
                        <a className="btn" href="https://www.instagram.com/yusufcatstevens/"><img src={Instagram} /></a>
                        <a className="btn" href="https://www.youtube.com/user/YusufCatStevens"><img src={Youtube} /></a>
                        <a className="btn" href="https://open.spotify.com/artist/08F3Y3SctIlsOEmKd6dnH8"><img src={Spotify} /></a>
                        <a className="btn" href="https://itunes.apple.com/us/artist/cat-stevens/107587"><img src={Music} /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
