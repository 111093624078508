import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import logo from "../assets/images/cs-logo.png"
import MenuCloseIcon from "../assets/images/menu-close-icon.svg"
import Navigation from "./Navigation"

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const sidebarClassNames = classNames("sidebar", { "show-sidebar": isOpen })

  return (
    <aside className={sidebarClassNames}>
      <div className="sidebar-header">
        <button className="close-btn" onClick={toggleSidebar}>
          <img src={MenuCloseIcon} alt="Close Menu" />
        </button>
        <a href="https://www.catstevens.com">
          <img src={logo} alt="logo" className="logo" width="160px" height="87px" />
        </a>
      </div>
      <div className="side-container">
        <Navigation styleClass="sidebar-links"></Navigation>
      </div>
    </aside>
  )
}

export default Sidebar
