import { Link } from "gatsby"
import React from "react"
import Navigation from "./Navigation"
import logo from "../assets/images/cs-logo.png"
import MenuIcon from "../assets/images/menu-icon.svg"
import SubNavigation from "./SubNavigation"

const Header = ({ toggleSidebar }) => {
  return (
    <header className="header">
      <div className="nav-center">
        <div className="nav-header">
          <a href="https://www.catstevens.com">
            <img src={logo} alt="logo" className="logo" width="160px" height="90.56" />
          </a>
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <img src={MenuIcon} alt="menuIcon" width={32.3} />
          </button>
        </div>
        <div className="nav-content">
          <Navigation styleClass="nav-links"></Navigation>
        </div>
      </div>
      <div className="subnav-menu">
        <div className="subnav-menu-center">
          <SubNavigation styleClass="nav-links"></SubNavigation>
        </div>
      </div>
    </header>
  )
}

export default Header
