import React, { useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
// import heart from "../assets/images/pt-heart-icon.svg"
import navigationLinks from "../constants/navigation-links"

const Navigation = ({ styleClass }) => {
  const [pathName, setPathName] = React.useState(false)

  const tempLinks =
    navigationLinks.map(link => {
      const itemClassNames = classNames({
        last: navigationLinks.length === link.id,
        currentPage: pathName === link.url
      })
      // const hasHeartIcon = link.id === 3

      const handleParentClick = e => {
        e.target.classList.toggle("active")
      }


      return (
        <li key={link.id} className={itemClassNames + ' hover-'+link.id}>
          {link.children ? (
            <>
              <span
                role="button"
                tabIndex={link.id}
                style={{ verticalAlign: "top", outline: "none" }}
                className={link.url === '/' ? "item-parent active" : "item-parent"}
                onClick={handleParentClick}
                onKeyDown={handleParentClick}
              >
                {link.text.toLocaleUpperCase()}
              </span>
              <ul className="sub-menu" style={{backgroundColor: link.color, marginLeft: "-10px"}}>
                {link.children.map(child => (
                  <li key={child.id} className={child.url === pathName && "currentPage"}>
                    <Link to={child.url}>
                      <span>{child.text.toLocaleUpperCase()}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
              <Link to={link.url}>
                <span style={{ verticalAlign: "top" }}>{link.text.toLocaleUpperCase()}</span>
                {/* {hasHeartIcon && <img src={heart} alt="heart-icon" />} */}
              </Link>
            )}
        </li>
      )
    })


  useEffect(() => {
    const path = window.location.pathname.split('/')
    if(path.length > 3) {
      setPathName('/'+path[1]+'/'+path[2])
    } else {
      setPathName(path.join('/'))
    }
  }, [pathName])

  return (
    <ul className={classNames("page-links", styleClass)}>{tempLinks}</ul>
  )
}

export default Navigation
