const links = [
  {
    id: 1,
    text: "NEWS",
    url: "https://catstevens.com/news/"
  },
  {
    id: 2,
    text: "LIFE",
    url: "https://catstevens.com/life/",
    color: '#be6845',
    children: [
      {
        id: 1,
        text: "Biography",
        url: "https://catstevens.com/life/",
      },
      {
        id: 2,
        text: "Editing Floor Blues",
        url: "https://catstevens.com/editing-floor-blues/",
      },
      {
        id: 3,
        text: "Quotes",
        url: "https://catstevens.com/life/quotes/",
      }
    ],
  },
  {
    id: 3,
    text: "MEDIA",
    url: "https://catstevens.com/media/albums/",
    color: '#b47f58',
    children: [
      {
        id: 1,
        text: "ALBUMS",
        url: "https://catstevens.com/media/albums/",
      },
      {
        id: 2,
        text: "VIDEOS",
        url: "https://catstevens.com/media/videos/",
      },
      {
        id: 3,
        text: "GALLERIES",
        url: "https://catstevens.com/media/galleries/",
      },
      {
        id: 4,
        text: "DVDS",
        url: "https://catstevens.com/media/dvds/",
      },
      {
        id: 5,
        text: "BOOKS",
        url: "https://catstevens.com/media/books/",
      },
      {
        id: 5,
        text: "SONGS",
        url: "https://catstevens.com/media/songs/",
      },
      {
        id: 5,
        text: "SINGLES",
        url: "https://catstevens.com/media/singles/",
      }
    ],
  },
  {
    id: 4,
    text: "EVENTS",
    url: "https://catstevens.com/upcoming-events/",
    color: '#cb9436',
    children: [
      {
        id: 1,
        text: "UPCOMING EVENTS",
        url: "https://catstevens.com/upcoming-events/",
      },
      {
        id: 2,
        text: "PAST EVENTS",
        url: "https://catstevens.com/past-events/",
      },
    ],
  },
  {
    id: 5,
    text: "THINK",
    url: "https://catstevens.com/think/",
    color: '#00c4d0',
    children: [
      {
        id: 1,
        text: "THINK HOME",
        url: "https://catstevens.com/think/",
      },
      {
        id: 2,
        text: "EVERYBODY’S JOURNEY",
        url: "https://catstevens.com/think/everybodys-journey/one-beginning/",
      },
      {
        id: 3,
        text: "SPIRITUAL DOMAIN",
        url: "https://catstevens.com/think/spiritual-domain/",
      },
      {
        id: 4,
        text: "JOSEPH EXPLORING",
        url: "https://catstevens.com/think/joseph-exploring/",
      },
      {
        id: 5,
        text: "TALKS",
        url: "https://catstevens.com/think/audiobooks/",
      }
    ],
  },
  {
    id: 6,
    text: "PEACETRAIN",
    url: "https://peacetrain.catstevens.com/",
    color: '#00b8ff',
    children: [
      {
        id: 1,
        text: "PEACE TRAIN HOME",
        url: "https://peacetrain.catstevens.com/",
      },
      {
        id: 2,
        text: "FEEDING",
        url: "https://peacetrain.catstevens.com/our-work/feeding",
      },
      {
        id: 3,
        text: "SAFE WATER",
        url: "https://peacetrain.catstevens.com/our-work/safe-water",
      },
      {
        id: 4,
        text: "PEACE TRAINING",
        url: "https://peacetrain.catstevens.com/our-work/peace-training",
      },
      {
        id: 5,
        text: "PLAYGROUNDS",
        url: "https://peacetrain.catstevens.com/our-work/playgrounds",
      },
      {
        id: 6,
        text: "PEACE PARTNERS",
        url: "https://peacetrain.catstevens.com/our-work/peace-partners",
      }
    ],
  },
  {
    id: 7,
    text: "MOL KIDS",
    url: "/",
    color: '#14548C',
    children: [
      {
        id: 1,
        text: "MOL KIDS HOME",
        url: "/",
      },
      {
        id: 2,
        text: "AUDIO",
        url: "https://catstevens.com/media/albums/",
      },
      {
        id: 3,
        text: "VIDEO",
        url: "/videos",
      },
      {
        id: 4,
        text: "DOWNLOADABLE PDFS",
        url: "https://catstevens.com/media/books/",
      },
    ],
  },
  {
    id: 8,
    text: "BAZAAR",
    url: "https://catstevens.lnk.to/Shop",
  },
]

export default links
